import React from 'react';
import Box from '@mui/system/Box';
import useTheme from '@mui/system/useTheme';
import ConnectChildrenWithWavesAnimationLazy from '../../_shared/ConnectChildrenWithWavesAnimationLazy';
import { getAnimationOptionsCCh } from '../../_shared/ConnectChildrenWithWavesAnimation';
import WavedFrameAnimationForChildrenLazy from '../../_shared/WavedFrameAnimationForChildrenLazy';
import { getDefaultConnectColors, myAnimationFramerMotionCCh, sxWrapItro, myBaseOptionsCCh } from '../../_shared/IntroTextyConnected';
import { myWavesOptionsCCh } from "../../_shared/wavesConfigurationsCreated"

const IntroTextNacoWebStrankyCCh2 = ({ colors = {} }) => {

    const theme = useTheme();
    const defaultThemeColors = getDefaultConnectColors(theme.palette);
    const usedColors = { ...defaultThemeColors, ...colors };
    const { svgPathColor, svgFillColor, startBg, startColor, endBg, endColor } = usedColors;

    const myAnimationOptionsCCh0 = getAnimationOptionsCCh({
        svgPathColor, svgFillColor, startBg, startColor, endBg, endColor, styleItemText: { backgroundColor: "#f3d", color: "#480" },
        animationFramerMotion: myAnimationFramerMotionCCh
    });

    return (
        <Box sx={sxWrapItro} className="intro">
            <h3 id="Preco-web-stranky2" className="toc"><span>Prečo webstránky</span></h3>
            <ConnectChildrenWithWavesAnimationLazy
                baseOptionsCCh={myBaseOptionsCCh}
                animationOptionsCCh={myAnimationOptionsCCh0}
                wavesOptionsCCh={myWavesOptionsCCh}
                name="Preco-web-stranky2"
            >
                <WavedFrameAnimationForChildrenLazy
                    // nameWavedFrames="baseB2"
                    // namePaddings="R10-B5-L10"
                    nameWavedFrames="baseT2R2B2L2_circ"
                    namePaddings="T10-R10-B10-L10"
                >
                    <div>Máme facebook, načo k tomu ešte webstránky?</div>
                </WavedFrameAnimationForChildrenLazy>
                <div>
                    <WavedFrameAnimationForChildrenLazy
                        // nameWavedFrames="baseT2R2B2L2"

                        nameWavedFrames="baseT2R2B2L2_strecha"

                        namePaddings="T10-R10-B10-L10"
                    >
                        <h4 id="Doveryhodnost" className="waved toc toc0"><span>Dôveryhodnosť.</span></h4>
                    </WavedFrameAnimationForChildrenLazy>
                    <div>Vlastné webstránky vám zvýšia dôveryhodnosť. Vo vašu firmu alebo osobu. A na webstránky sa tiež môžu dostať všetci, aj takí návštevníci. ktorí nežijú na sociálnych sieťach.</div>
                </div>
            </ConnectChildrenWithWavesAnimationLazy>
        </Box>

    );
};
export default IntroTextNacoWebStrankyCCh2;